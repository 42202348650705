import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar4 from "../../components/Sidebar4";
import { Button, Text } from "../../components";
import { Helmet } from "react-helmet";

export default function VideoRepoPage() {
  const [videos, setVideos] = useState([]);
  const [selectedTab, setSelectedTab] = useState("official");

  useEffect(() => {
    fetchVideos("official");
  }, []);

  const fetchVideos = async (type) => {
    const endpoint = type === "official" ? "https://migolearn.live/api/official-videos" : "https://migolearn.live/api/reference-videos";
    try {
      const response = await axios.get(endpoint, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setVideos(response.data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleTabClick = (type) => {
    setSelectedTab(type);
    fetchVideos(type);
  };

  const getVideoName = (videoUrl) => {
    const segments = videoUrl.split('/');
    const fileName = segments[segments.length - 1];
    const nameWithoutExtension = fileName.replace(/\.[^/.]+$/, ""); // Remove file extension
    return decodeURIComponent(nameWithoutExtension); // Decode URL encoding
  };
  
  return (
    <>
      <Helmet>
        <title>Video Repository</title>
        <meta name="description" content="Video repository page for official and reference videos." />
      </Helmet>
      <div className="flex">
        <Sidebar4 bgColor="#E0FAFF" />
        <div className="flex w-[74%] flex-col items-center mt-5 gap-[36px]">
        <Text size="6xl" as="p" className="!font-hind">
              Video Repository
            </Text>
          <div className="flex gap-4 mb-8">
            <Button
              className={`px-4 py-2 rounded ${selectedTab === "official" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
              onClick={() => handleTabClick("official")}
            >
              Official Videos
            </Button>
            <Button
              className={`px-4 py-2 rounded ${selectedTab === "reference" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
              onClick={() => handleTabClick("reference")}
            >
              Reference Videos
            </Button>
          </div>
          <div className="grid grid-cols-3 gap-6 justify-center md:grid-cols-2 sm:grid-cols-1 mt-10">
          {videos.map((video, index) => (
              <div
                key={"gridvideo" + index}
                className="relative w-full border border-solid border-gray-300 shadow-md rounded-lg"
              >
                <video controls className="w-full h-[148px] object-cover">
                  <source src={video.videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <Text as="p" className="text-center mt-2 text-gray-800 font-semibold">
                  {getVideoName(video.videoUrl)}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
