import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Button, Text } from '../../components';
import { useNavigate } from "react-router-dom";
import LessonAIPagePPTOneCard1 from '../../components/LessonAIPagePPTOneCard1';
import Sidebar4 from '../../components/Sidebar4';
import CircularLoadingBar from '../../components/CircularLoadBar/CircularLoadBar'; 

const data = [
  { templatecounter: 'Template 1', selectTemplate: 'Quotable.pptx' },
  { templatecounter: 'Template 2', selectTemplate: 'Gallery.pptx' },
  { templatecounter: 'Template 3', selectTemplate: 'Dividend.pptx' },
  { templatecounter: 'Template 4', selectTemplate: 'Berlin.pptx' },
];

export default function LessonAIPagePPTOnePage() {
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

const handleSubmit = async () => {
  if (!selectedTemplate) {
    alert('Please select a template');
    return;
  }

  setIsLoading(true);
  setError(false);

  const token = localStorage.getItem('token');
  console.log('token:', token);

  try {
    // First API call
    const response = await axios.post(
      `https://migolearn.live/api/json_script?template=${selectedTemplate}`,
      {}, // Axios requires an empty object for body when no data is sent
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      const jsonScriptResponse = response.data;
      console.log('Response:', jsonScriptResponse);

      // Second API call
      const generatePptResponse = await axios.post(
        'https://migolearn.live/api/generate_ppt',
        {}, // Empty body
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (generatePptResponse.status === 200) {
        const pptResult = generatePptResponse.data;
        console.log('PPT Generation Response:', pptResult);
        navigate("/LessonAIpagepptthree");
      } else {
        console.error('Error generating PPT:', generatePptResponse.status);
        setError(true);
      }
    } else {
      console.error('Error:', response.status);
      setError(true);
    }
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
    setError(true);
  } finally {
    setIsLoading(false); // Hide loading bar
  }
};

         

  const handleRetry = () => {
    setError(false);
    handleSubmit();
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name='description' content='Web site created using create-react-app' />
      </Helmet>
      <div className='flex w-full items-start justify-between gap-5 bg-white-A700 pr-28 md:flex-col md:pr-5'>
       <Sidebar4 bgColor="#FFF5F7" />
        <div className='mt-[26px] flex w-[68%] flex-col items-center md:w-full'>
        <Text size='6xl' as='p'>
            Select Template for Presentation
          </Text>
          <div className='mt-[46px] grid grid-cols-2 justify-center gap-[50px] self-stretch md:grid-cols-1'>
            {data.map((d, index) => (
              <LessonAIPagePPTOneCard1
                {...d}
                key={'gridtemplatecou' + index}
                onSelect={handleTemplateSelect}
                isSelected={selectedTemplate === d.selectTemplate} // Pass selected status
              />
            ))}
          </div>
          <div className='mt-[85px] flex pb-[7px]'>
            <Button
              size='lg'
              className='min-w-[116px] rounded font-poppins bg-[#FFAEC0] font-medium sm:px-5'
              onClick={() => {
                handleSubmit();
              }}
            >
              Generate Presentation
            </Button>
          </div>
        </div>
      </div>
      <CircularLoadingBar
        isLoading={isLoading}
        error={error}
        comment="Generating your presentation, please wait..."
        onRetry={handleRetry} /> 
    </>
  );
}
