import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import { useNavigate } from "react-router-dom";

export default function LessonAIPageCompletionPage() {

  const navigate = useNavigate();
  
  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[202px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-[209px] flex w-[57%] flex-col items-center md:w-full">
          <Img src="images/img_checkmark.svg" alt="checkmarkone" className="h-[72px] w-[72px]" />
          <Text size="6xl" as="p" className="mt-[33px] !text-[38px]">
            You have Successfully Created a Lesson
          </Text>
          <div className="mt-[60px] flex w-[65%] justify-between gap-5 md:w-full md:p-5">
           
              <Button color="light_green_900" shape="round" 
              className="min-w-[207px] bg-[#21A2FF] text-black !rounded-lg font-medium sm:px-5" 
              onClick={() => navigate("/LessonAIpagealllessons")}>
                View Lessons
              </Button>
       
            <Button color="light_green_900" shape="round" 
            className="min-w-[207px] bg-[#21A2FF] text-black !rounded-lg font-medium sm:px-5"
            onClick={() => navigate('/LessonAIpagemain')}>
              Dashboard
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
