import { React, useState } from "react";
import { Helmet } from "react-helmet";
import {Button} from "../../components";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import CircularLoadingBar from '../../components/CircularLoadBar/CircularLoadBar'; 
import Sidebar4 from "../../components/Sidebar4";
const ToggleSwitch = ({ id, checked, onChange, optionLabels }) => {
  return (
    <div className="relative inline-block w-20 align-middle select-none transition duration-200 ease-in">
      <input
        type="checkbox"
        name={id}
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="toggle-checkbox sr-only"
      />
      <label
        htmlFor={id}
        className={`toggle-label block w-full h-8 rounded-full cursor-pointer transition-all duration-300 ease-in-out
          ${checked ? 'bg-blue-500' : 'bg-gray-300'}`}
      >
        {/* Text inside the toggle */}
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 text-white text-sm font-semibold transition-transform duration-300 ease-in-out
            ${checked ? 'left-2' : 'right-2'}`
          }
        >
          {checked ? optionLabels[1] : optionLabels[0]}
        </div>

        {/* Circle element */}
        <span
          className={`absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out
            ${checked ? 'translate-x-12' : 'translate-x-0'}`}
        />
      </label>
    </div>
  );
};


export default function LessonAIPageVideoOnePage() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isHindi, setIsHindi] = useState(false);  // Toggle for language (default is English)
  const [isFemale, setIsFemale] = useState(true); // Toggle for gender (default is Female)
  
  const navigate = useNavigate();

  const handleGenerateVideo = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    setError(false);
    
    const requestBody = {
      slides: [ /* Array of slide data that you want to send to the API */ ],
      targetLanguage: isHindi ? 'hi-IN' : 'en-IN',  // Choose language based on toggle
      speaker_gender: isFemale ? 'Female' : 'Male', // Choose gender based on toggle
      speaker_voice: 'meera'
    };
    
    try {
      const response = await axios.post(
        'https://migolearn.live/api/generate_video',
        requestBody,
        {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.status === 200) {
        setError(false);
        console.log('Response:', response.data);
        navigate("/LessonAIpagevideotwo");
      } else {
        setError(true);
        console.error('Error:', response.status);
      }
    } catch (error) {
      setError(true);
      console.error('Error generating video:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRetry = () => {
    setError(false);
    handleGenerateVideo();
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[376px] md:flex-col md:pr-5">
      <Sidebar4 bgColor="#FFF5F7" />
        <div className="flex flex-col items-center mr-[80px] mt-20">
          <CircularLoadingBar
            isLoading={isLoading}
            error={error}
            comment="Generating your Videos, please wait..."
            onRetry={handleRetry} 
          />

          {/* Toggle for selecting language */}
          <div className="flex items-center mt-5">
            <label className="mr-2">Language:</label>
            <ToggleSwitch 
              id="language-toggle"
              checked={isHindi} 
              onChange={setIsHindi} 
              optionLabels={["en-IN", "hi-IN"]} 
            />
          </div>

          {/* Toggle for selecting gender */}
          <div className="flex items-center mt-5">
            <label className="mr-2">Gender:</label>
            <ToggleSwitch 
              id="gender-toggle"
              checked={isFemale} 
              onChange={setIsFemale} 
              optionLabels={["Male", "Female"]} 
            />
          </div>

          {/* Submit button to generate video */}
            <Button
                shape="round"
                className="w-[14rem] mt-5 bg-[#FFAEC0] font-medium"
                disabled={isLoading}
                onClick={handleGenerateVideo}>
                {isLoading ? "Generating..." : "Generate Video"}
            </Button>
        </div>
      </div>
      <style>{`
        .toggle-checkbox:checked + .toggle-label {
          background-color: #FFAEC0;
        }
        .toggle-checkbox {
          display: none;
        }
      `}</style>
    </>
  );
}

// import {React,useState,useEffect} from "react";
// import { Helmet } from "react-helmet";
// import axios from 'axios';
// import { useNavigate } from "react-router-dom";
// import { Text } from "../../components";
// import Sidebar4 from "../../components/Sidebar4";
// import CircularLoadingBar from '../../components/CircularLoadBar/CircularLoadBar'; 

// export default function LessonAIPageVideoOnePage() {

//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(false);


//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     console.log('token:', token);
//     GenerateVideo(); 
//     setIsLoading(true);
//     setError(false);
//   }, []);


//   const GenerateVideo = async () => {
//     const token = localStorage.getItem('token');
//     console.log('token:', token);
//     try {
//       const response = await axios.post(
//         'https://migolearn.live/api/generate_video',
//         {},
//         {
//           headers: { 
//             'Authorization': `Bearer ${token}`,
//             'Content-Type': 'application/json' 
//           }
//         }
//       );
//       if (response.status === 200) { 
//         setError(false);
//         console.log('Response:', response.data);
//         navigate("/LessonAIpagevideotwo");
//       } else {
//         setError(true);
//         console.error('Error:', response.status);
//       }
//     } catch (error) {
//       setError(true);
//       console.error('Error generating video:', error);
//     }
//   };

//   const handleRetry = () => {
//     setError(false);
//     GenerateVideo();
//   };

//   return (
//     <>
//       <Helmet>
//         <title>LearnMigo</title>
//         <meta name="description" content="Web site created using create-react-app" />
//       </Helmet>
//       <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[376px] md:flex-col md:pr-5">
//         <Sidebar4 />
//         {/* <div className="mt-[283px] flex w-[37%] flex-col items-center gap-[51px] md:w-full sm:gap-[25px]"> */}
//           {/* <div className="w-[23%] rounded-[5px] border border-solid border-blue_gray-100 p-5 md:w-full"> */}
//           <CircularLoadingBar
//             isLoading={isLoading}
//             error={error}
//             comment="Generating your Videos, please wait..."
//             onRetry={handleRetry} /> 
//           </div>
//           {/* <Text size="6xl" as="p" className="!text-[38px]">
//             Generating The Video
//           </Text> */}
//         {/* </div> */}
//       {/* </div> */}
//     </>
//   );
// }