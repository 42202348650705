import { React, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Text, Img, Button } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import LessonAIPageLOThreeSublearning from "../../components/LessonAIPageLOThreeSublearning";
import axios from "axios";

export default function LessonAIPageLOThreePage() {
  const [finalLearningOutcomes, setFinalLearningOutcomes] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchLearningOutcomes = async () => {
      try {
        let response;
        if (id) {
          // Fetch specific learning outcome by ID
          response = await axios.get(`https://migolearn.live/api/learning-outcomes/${id}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}` // Include the token in the request headers
            }
          });
        } else {
          // Fetch the newly generated learning outcomes
          response = await axios.get('https://migolearn.live/api/learning-outcomes', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}` // Include the token in the request headers
            }
          });
        }
  
        const data = response.data;
        console.log('API response data:', data);
  
        // Extract and parse the JSON part of final_learning_outcomes if present
        const jsonString = data.final_learning_outcomes.match(/```json([\s\S]*?)```/)[1].trim();
        const parsedData = JSON.parse(jsonString);
  
        if (parsedData && Array.isArray(parsedData.learningOutcomes)) {
          setFinalLearningOutcomes(parsedData.learningOutcomes);
        } else {
          console.error('Invalid data format received:', parsedData);
        }
      } catch (error) {
        console.error('Error fetching final learning outcomes:', error);
      }
    };
  
    fetchLearningOutcomes();
  }, [id]);
  
  useEffect(() => {
    console.log('Final Learning Outcomes State:', finalLearningOutcomes);
    console.log('Type of Final Learning Outcomes:', typeof finalLearningOutcomes);
    if (Array.isArray(finalLearningOutcomes)) {
      console.log('Final Learning Outcomes is an array');
    } else {
      console.error('Final Learning Outcomes is not an array');
    }
  }, [finalLearningOutcomes]);
  

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-A700_03 pr-[70px] md:pr-5">
        <div className="flex items-start justify-between gap-5 md:flex-col">
        <Sidebar4 bgColor="#E0FAFF" />
          <div className="mt-[79px] flex w-[72%] flex-col items-center gap-[34px] md:w-full md:p-5 ">
            <Text size="6xl" as="p" className="mt-[-40px] !font-hind !text-black-900_02">
              These Are Your Learning Outcomes :
            </Text>
            {Array.isArray(finalLearningOutcomes) && finalLearningOutcomes.length === 0 ? (
              <Text size="4xl" as="p" className="text-center !font-hind !text-black-900_02">
                No learning outcomes available.
              </Text>
            ) : (
              Array.isArray(finalLearningOutcomes) && finalLearningOutcomes.map((outcome, index) => (
                <div key={index} className="self-stretch rounded-[20px] border border-solid border-blue_gray-100_02  p-[13px] mb-[34px]">
                  <div className="mb-[7px] flex flex-col gap-0.5">
                    <div className="flex justify-center pr-5">
                    <Text size="5xl" as="p" className="text-left !font-hind !text-[22px] !text-black-900_02 "> 
                    {/* whitespace-nowrap */}
                      <span className="text-black-900_02">Terminal Outcome :&nbsp;</span>
                      <span className="text-base font-normal text-black-900_02 mr-[140px]">
                        {outcome.terminalOutcome}
                      </span>
                    </Text>
                    </div>
                    <div className="flex flex-col items-start">
                      {/* <Img src="images/img_big_arrow.svg" alt="bigarrow" className="h-[217px] w-[3%] md:w-full" /> */}
                      {outcome.subLearningOutcomes.map((subOutcome, subIndex) => (
                        <LessonAIPageLOThreeSublearning
                          key={subIndex}
                          sublearningoutcome={`Sub-Learning Outcome: ${subOutcome.id}`}
                          outcome={subOutcome.outcome}
                          examplesdescription={subOutcome.examples.join(', ')}
                          modeofdelivery={subOutcome.modeOfDelivery}
                          timerequired={subOutcome.timeRequired}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))
            )}
            <Button
                 
                  shape="round"
                  className="w-[10rem] bg-[#6BDFEC] font-medium"
                  onClick={() => navigate("/LessonAIpageflowdocone")}>
                    Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
