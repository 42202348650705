
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Text } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import { useLocation } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import axios from 'axios';

export default function LessonAIPagePPTTwoPage() {
  const location = useLocation();
  const selectedPpt = location.state?.ppt || {};
  const [pptLink, setPptLink] = useState('');
  const [scripts, setScripts] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    if (selectedPpt.ppt) {
      fetchPPT(selectedPpt.ppt);
      fetchScripts(selectedPpt.ppt);
    }
  }, [selectedPpt]);

  const fetchPPT = async (pptName) => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    try {
      const response = await axios.get('https://migolearn.live/api/get_ppt_url', {
        params: { pptName },
        headers: { 'Authorization': `Bearer ${token}` } 
      });
      setPptLink(response.data.pptUrl);
    } catch (error) {
      console.error('Error fetching PPT URL:', error);
    }
  };

  const fetchScripts = async (pptName) => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    try {
      const response = await axios.get('https://migolearn.live/api/get_script', {
        params: { pptName },
        headers: { 'Authorization': `Bearer ${token}` } 
      });
      setScripts(response.data.scripts);
    } catch (error) {
      console.error('Error fetching scripts:', error);
    }
  };

  const handleDownloadPPT = () => {
    if (pptLink) {
      window.open(pptLink, '_blank');
    }
  };

  const handleScriptChange = (index, value) => {
    const newScripts = [...scripts];
    newScripts[index] = value;
    setScripts(newScripts);
  };

  const handleSaveScripts = async () => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    try {
      await axios.put('https://migolearn.live/api/update_script', {
        pptName: selectedPpt.ppt,
        updatedScripts: scripts
      }, {
        headers: { 'Authorization': `Bearer ${token}` } 
      });
      alert('Scripts updated successfully');
    } catch (error) {
      console.error('Error updating scripts:', error);
      alert('Failed to update scripts');
    }
  };
  
  

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[82px] md:flex-col md:pr-5">
       <Sidebar4 bgColor="#FFF5F7" />
        <div className="mt-[54px] flex w-[71%] flex-col items-center gap-[31px] md:w-full">
          <Text size="6xl" as="p">
            Presentation and Script Preview
          </Text>
          <div className="flex self-stretch md:flex-col">
            <div className="h-[472px] flex-1 border border-solid border-black-900 md:self-stretch md:p-5">
              {pptLink ? (
                <DocViewer
                  documents={[{ uri: pptLink, fileType: 'pptx' }]}
                  pluginRenderers={DocViewerRenderers}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : 'No PPT available'}
            </div>
            <div className="h-[472px] w-[31%] border border-solid border-black-900 md:p-5 overflow-auto">
              {scripts.length > 0 ? (
                scripts.map((script, index) => (
                  <div key={index}>
                    <label>{`Slide ${index + 1} Script:`}</label>
                    <textarea
                      value={script}
                      onChange={(e) => handleScriptChange(index, e.target.value)}
                      className="w-full text-sm border border-gray-300 p-2"
                    />
                  </div>
                ))
              ) : (
                'No script available'
              )}
            </div>
          </div>
          <div className="flex justify-center gap-5 mt-5 w-full">
          <Button onClick={handleDownloadPPT} shape="round" className="min-w-[207px] !rounded-lg bg-[#FFAEC0] font-mediums sm:px-5">
            Download PPT
          </Button>
          <Button onClick={handleSaveScripts} shape="round" className="min-w-[207px] !rounded-lg bg-[#FFAEC0] font-medium sm:px-5 ">
            Save Scripts
          </Button>
          </div>
        </div>
      </div>
    </>
  );
}
