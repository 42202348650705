import React, { useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Button, Input, Text, SelectBox, Img, FileUpload } from "../../components";
import Sidebar2 from "../../components/Sidebar2";
import ProfilePictureUpload from '../ProfAIPage/ProfilePictureUpload';
import { HiOutlineArrowUpCircle } from "react-icons/hi2";
import UploadedFiles from './UploadedFiles';


const dropDownOptionsLS = [
  { label: "Verbal", value: "verbal" }, 
  { label: "Active", value: "active" },
  { label: "Intuitive", value: "intuitive" },
  { label: "Reflective", value: "reflective" },
  { label: "Global", value: "global" }
];

const dropDownOptionsCom = [
  { label: "Layman", value: "layman" },
  { label: "Story Telling", value: "story telling" },
  { label: "Socratic", value: "socratic" },
];

const dropDownOptionsInt = [
  { label: "Encouraging", value: "encouraging" },
  { label: "Informative", value: "informative" },
  { label: "Friendly", value: "friendly" },
  { label: "Humorous", value: "humorous" },
  { label: "Empathetic", value: "empathetic" }
];

const dropDownOptionsRF = [
  { label: "Deductive", value: "deductive" },
  { label: "Inductive", value: "inductive" },
  { label: "Abductive", value: "abductive" },
  { label: "Analogical", value: "analogical" },
  { label: "Causal", value: "causal" }
];

const dropDownOptionsSub = [
  { label: "Maths", value: "maths" },
  { label: "Science", value: "science" },
  { label: "Chemistry", value: "chemistry" },
];

const dropDownOptionsQual = [
  { label: "Undergrad", value: "Undergrad" }, 
  { label: "Postgrad", value: "Postgrad" },
  { label: "High School", value: "High School" },
];
  

export default function ProfAIUpdatePagePage() {
  const [formData, setFormData] = useState({
    name: '',
    profile_picture: null,
    description: '',
    greeting: '',
    learning_style: [],
    communication: '',
    tone: '',
    reasoning_frameworks: [],
    subjects: [],
    rules: '',
    student_description: '',
    starters: '',
    uploaded_files: [],
  });

  // const { avatarId } = useParams();

useEffect(() => {
  const fetchLatestAvatarData = async () => {
    try {
      const response = await fetch('https://migolearn.live/api/latest-avatar');
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched latest avatar data:', data);

        const prefillDropdown = (field, options) => {
          const selectedOption = options.find(option => option.value === data[field]);
          return selectedOption ? selectedOption : null; // Return the selected option object or null
        };

        const preFilledData = {
          learning_style: prefillDropdown('learning_style', dropDownOptionsLS),
          communication: prefillDropdown('communication', dropDownOptionsCom),
          tone: prefillDropdown('tone', dropDownOptionsInt),
          reasoning_frameworks: prefillDropdown('reasoning_frameworks', dropDownOptionsRF),
          subjects: prefillDropdown('subjects', dropDownOptionsSub),
          student_description: prefillDropdown('student_description', dropDownOptionsQual),
        };

        setFormData(prevFormData => ({
          ...prevFormData,
          ...data,
          ...preFilledData,
          // ...(data.uploaded_files && { uploaded_files: data.uploaded_files }),
          // uploaded_files: data.uploaded_files || [],
        }));
        
        console.log('Fetched latest avatar formData:', formData);
      } else {
        console.error('Failed to fetch latest avatar data');
      }
    } catch (error) {
      console.error('Error fetching latest avatar data:', error);
    }
  };

  fetchLatestAvatarData();
}, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log('Form submitted with data:', formData);
    
  //   try {
  //     const restructuredData = {
  //       name: formData.name,
  //       profile_picture: formData.profile_picture,
  //       description: formData.description,
  //       greeting: formData.greeting,
  //       learning_style: formData.learning_style.value || '', 
  //       communication: formData.communication.value,
  //       tone: formData.tone.value, 
  //       reasoning_frameworkss: formData.reasoning_frameworks.value || '', 
  //       subjects: formData.subjects.value || '', 
  //       rules: formData.rules,
  //       starters: formData.starters, 
  //       student_description: formData.student_description.value,
  //       uploaded_files: formData.uploaded_files,
  //     };
  //     console.log('Form submitted with redata:', restructuredData);
  
  //     const response = await fetch('https://migolearn.live/api/avatars/latest', {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(restructuredData),
  //     });
  
  //     if (response.ok) {
  //       console.log('Avatar data updated successfully');
  //       // Handle the success case as needed
  //     } else {
  //       console.error('Failed to update avatar data');
  //       // Handle the failure case as needed
  //     }
  //   } catch (error) {
  //     console.error('Error updating avatar data:', error);
  //     // Handle errors from the fetch operation
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with data:', formData);
  
    try {
      const restructuredData = {
        name: formData.name,
        profile_picture: formData.profile_picture,
        description: formData.description,
        greeting: formData.greeting,
        learning_style: formData.learning_style?.value || '',
        communication: formData.communication?.value || '',
        tone: formData.tone?.value || '',
        reasoning_frameworks: formData.reasoning_frameworks?.value || '',
        subjects: formData.subjects?.value || '',
        rules: formData.rules,
        starters: formData.starters,
        student_description: formData.student_description?.value || '',
        // uploaded_files: formData.uploaded_files,
      };

      console.log('Form submitted with redata:', restructuredData);
  
      const formDataToSend = new FormData();
      formDataToSend.append('data', JSON.stringify(restructuredData));
  
      for (const file of formData.uploaded_files) {
        formDataToSend.append('uploaded_files', file);
      }
  
      const response = await fetch('https://migolearn.live/api/avatars/latest', {
        method: 'PUT',
        body: formDataToSend,
      });
  
      if (response.ok) {
        console.log('Avatar data updated successfully',formData);
      } else {
        console.error('Failed to update avatar data');
      }
    } catch (error) {
      console.error('Error updating avatar data:', error);
    }
  };
  

  const handleSelectChange = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value ? value : [],
    }));
  };

  const handleProfilePictureChange = (picture) => {
    setFormData({ ...formData, profile_picture: picture });
  };

  const handleFileUpload = (files) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      uploaded_files: [...files, ...prevFormData.uploaded_files],
    }));
  };
  
  const handleFileRemoval = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      uploaded_files: prevFormData.uploaded_files.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[352px] md:flex-col md:pr-5">
        <Sidebar2 />
        <div className="flex w-[40%] flex-col gap-7 md:w-full">
          <div className="flex flex-col items-center">
            <Text size="6xl" as="p">
              Update Your Avatar
            </Text>
            <form onSubmit={handleSubmit} id="avatar-form" className="flex flex-col gap-[19px] self-stretch">
              <div className="relative h-[202px]">
                <div className="absolute bottom-[-0.29px] left-0 right-0 m-auto flex w-full flex-col items-end">
                  <div className="relative z-[1] flex h-[80px] w-[345px] ml-1 -mb-3 flex-col items-start md:w-full md:p-5">
                      <Text as="p" className="relative z-[2] tracking-[0.50px] !text-blue_gray-900_01">
                        Upload Materials{" "}
                      </Text>
                      <FileUpload
                        allowMultiple
                        preview
                        name="uploadedFiles"
                        // Thumbnail={(file, index) => (
                        //   <div key={index} className="relative flex items-center gap-2">
                        //     <Img src="path/to/file-icon.svg" alt="File Icon" className="h-[24px] w-[24px]" />
                        //     <Text as="p">{file.fileName || file.name}</Text>
                        //     <button onClick={() => handleFileRemoval(index)}>Remove</button>
                        //   </div>
                        // )}
                        onUpload={handleFileUpload}
                        initialFiles={formData.uploaded_files}
                        onRemove={handleFileRemoval}
                        // initialFiles={formData.uploaded_files}
                        // placeholder={() => <>[object Object] [object Object]</>}
                        className="relative flex h-[46px] items-center gap-2 self-stretch rounded-lg border border-solid border-gray-400 bg-white-A700 px-4 py-[11px]"
                      >
                        <Img src="images/img_close.svg" alt="closeone" className="h-[23px] w-[24px]" />
                        <div className="flex">
                          <Text as="p" className="!font-normal !text-gray-500">
                            Upload your Materials for ProfAI
                          </Text>
                        </div>
                      </FileUpload>
                      {/* <UploadedFiles uploadedFiles={formData.uploaded_files} /> */}
                    </div>
                    <div className="relative mt-[-18px] flex flex-col items-start gap-1.5 self-stretch">
                    <Text as="p" className="!text-blue_gray-900_01">
                      Short Description
                    </Text>
                    <Input
                      type='text'
                      shape="round"
                      name="description"
                      placeholder={`Description`}
                      className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                      value={formData.description}
                      onChange={description => handleInputChange({ target: { value: description, name: 'description' } })}
                    />
                  </div>
                </div>
                <div className="absolute right-[0.00px] top-[0.31px] m-auto h-[76px] w-[69%] md:h-auto">
                  <div className="ml-[43px] flex items-start gap-[7px] md:ml-0">
                    <Img
                      src="images/img_outline_interface_attention.svg"
                      alt="outlinethree"
                      className="h-[16px] w-[16px]"
                    />
                    <Text as="p" className="!text-gray-500">
                      Click here for Help
                    </Text>
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max w-full flex-col items-start gap-[7px]">
                    <Text as="p" className="!text-blue_gray-900_01">
                      Name
                    </Text>
                    <Input
                      shape="round"
                      type="text"
                      name="name"
                      placeholder={`Name of your Avatar`}
                      className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                      value={formData.name}
                      onChange={name => handleInputChange({ target: { value: name, name: 'name' } })}
                    />
                  </div>
                </div>
                <div className="absolute left-[0.00px] top-[0.00px] m-auto flex flex-col items-center gap-1.5">
                <ProfilePictureUpload onPictureChange={handleProfilePictureChange} initialPicture={formData.profile_picture} />
                </div>
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  How would you like your Avatar to greet?
                </Text>
                <Input
                  shape="round"
                  name="greeting"
                  placeholder={`Enter how would you like your avatar to greet?...`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  onChange={greeting => handleInputChange({ target: { value: greeting, name: 'greeting' } })}
                  value={formData.greeting}
                />
              </div>
              <div className="flex items-center gap-4 sm:flex-col">
                <div className="flex w-full flex-col items-start gap-1.5 sm:w-full sm:p-5">
                  <Text as="p" className="!text-blue_gray-900_01">
                    Learning Styles
                  </Text>
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                    name="learning_style"
                    placeholder={`Choose learning styles...`}
                    options={dropDownOptionsLS}
                    className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                    value={formData.learning_style}
                    onChange={(learning_style) => handleSelectChange('learning_style', learning_style)}
                  />
                </div>
                <div className="flex w-full flex-col items-start gap-[7px] sm:w-full sm:p-5">
                  <Text as="p" className="!text-blue_gray-900_01">
                    Communication
                  </Text>
                  <SelectBox
                    shape="round"
                    indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                    name="communication"
                    placeholder={`Enter communication...`}
                    options={dropDownOptionsCom}
                    className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                    value={formData.communication}
                    onChange={communication => handleInputChange({ target: { value: communication, name: 'communication' } })}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  How would you like your avatar to interact with them?
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="tone"
                  placeholder={`Suggest a tone, level of detail, tutoring style, etc.`}
                  options={dropDownOptionsInt}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.tone}
                  onChange={tone => handleInputChange({ target: { value: tone, name: 'tone' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Reasoning Frameworks
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="reasoning_frameworks"
                  placeholder={`Choose reasoning frameworks...`}
                  options={dropDownOptionsRF}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.reasoning_frameworks}
                  onChange={(reasoning_frameworks) => handleSelectChange('reasoning_frameworks', reasoning_frameworks)}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Which subjects do you teach?
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="subjects"
                  placeholder={`Choose which subjects do you teach?...`}
                  options={dropDownOptionsSub}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.subjects}
                  onChange={(subjects) => handleSelectChange('subjects', subjects)}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  What rules do you want to set for your Avatar?
                </Text>
                <Input
                  shape="round"
                  name="rules"
                  placeholder={`Establish clear rules for tones with your students.`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  value={formData.rules}
                  onChange={rules => handleInputChange({ target: { value: rules, name: 'rules' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  How would you describe your students?
                </Text>
                <SelectBox
                  shape="round"
                  indicator={<Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[20px] w-[20px]" />}
                  name="student_description"
                  placeholder={`Choose their Current Qualification`}
                  options={dropDownOptionsQual}
                  className="gap-px self-stretch border border-solid border-gray-400 font-hind sm:pr-5"
                  value={formData.student_description}
                  onChange={student_description => handleInputChange({ target: { value: student_description, name: 'student_description' } })}
                />
              </div>
              <div className="flex flex-col items-start gap-1.5">
                <Text as="p" className="!text-blue_gray-900_01">
                  Conversation Starters for you students
                </Text>
                <Input
                  shape="round"
                  name="starters"
                  placeholder={`eg. “Hello students, what doubts do you want me to resolve? ”`}
                  className="!rounded-lg border-gray-400 font-hind sm:pr-5"
                  value={formData.starters}
                  onChange={starters => handleInputChange({ target: { value: starters, name: 'starters' } })}
                />
              </div>
            </form>
          </div>
          <div className="flex gap-[15px]">
              <Button
                color="white_A700"
                shape="round"
                className="min-w-[207px] !rounded-lg border border-solid border-blue_gray-900_01 font-medium sm:px-5"
                onClick={() => console.log("Cancel clicked")}
              >
                Cancel
              </Button>
              <Button 
              type="submit"
              form="avatar-form"
              color="light_green_900"
              shape="round"
              className="min-w-[207px] !rounded-lg font-medium sm:px-5"
              onClick={() => alert("Updated Avatar Successfully")}
              >
                Submit
              </Button>
          </div>
        </div>
      </div>
    </>
  );
}
