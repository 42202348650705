import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Text, Button, Img, CheckBox, Input } from "../../components";

export default function SignUpPagePage() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false,
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) newErrors.username = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.password) newErrors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    if (!formData.termsAccepted) newErrors.termsAccepted = 'You must accept the terms & policy';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) {
      // Handle the case where 'name' is undefined
      console.error("Input field has no 'name' attribute.");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

 const handleSubmit = async (e) => {
  e.preventDefault();
  
  // Validate the form
  if (!validateForm()) return;

  try {
    // Send registration request
    const response = await fetch('https://migolearn.live/api/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    const data = await response.json();

    if (response.ok) {
      // Store the token in localStorage
      localStorage.setItem('token', data.token);

      // Navigate directly to the dashboard
      navigate('/LessonAIpagemain');
    } else {
      // Handle server errors
      setErrors({ server: data.error });
    }
  } catch (error) {
    console.error('Error:', error);
    setErrors({ server: 'An unexpected error occurred' });
  }
};


  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[125px] md:flex-col md:pr-5">
        <div className="flex w-[56%] flex-col items-start justify-center gap-[27px] rounded-br-[80px] rounded-tr-[80px] bg-indigo-300_01 px-[27px] pb-[161px] pt-5 md:w-full md:p-5 md:pb-5 sm:px-5">
          <Img src="images/img_header_logo.svg" alt="headerlogoone" className="h-[44px] w-[44px]" />
          <div className="mr-[70px] flex w-[79%] flex-col gap-[38px] self-end md:w-full">
            <Img src="images/img_student_is_studying.png" alt="studentisone" className="h-[365px] object-cover" />
            <Img src="images/img_student_draws_a.png" alt="studentdrawsa" className="h-[369px] object-cover" />
          </div>
        </div>
        <div className="mt-40 flex w-[31%] flex-col items-center md:w-full">
          <Text size="5xl" as="p" className="self-start">
            Get Started Now
          </Text>
          <form className="mt-[62px] flex flex-col items-start self-stretch" onSubmit={handleSubmit}>
            <div className="flex flex-col items-start self-stretch pb-1">
              <Text as="p">Name</Text>
              <Input
                color="black_900_66"
                size="xs"
                variant="outline"
                shape="round"
                type="text"
                name="username"
                placeholder={`Enter your name`}
                value={formData.username}
                onChange={username => handleInputChange({ target: { value: username, name: 'username' } })}
                className="font-medium sm:pr-5"
              />
              {errors.username && <Text color="red" as="p">{errors.username}</Text>}
            </div>
            <div className="flex flex-col items-start self-stretch pb-1 mt-[19px]">
              <Text as="p">Email</Text>
              <Input
                color="black_900_66"
                size="xs"
                variant="outline"
                shape="round"
                type="email"
                name="email"
                placeholder={`Enter your email`}
                value={formData.email}
                onChange={email => handleInputChange({ target: { value: email, name: 'email' } })}
                className="font-medium sm:pr-5"
              />
              {errors.email && <Text color="red" as="p">{errors.email}</Text>}
            </div>
            <div className="flex flex-col items-start self-stretch pb-1 mt-[19px]">
              <Text as="p">Password</Text>
              <Input
                color="black_900_66"
                size="xs"
                variant="outline"
                shape="round"
                type="password"
                name="password"
                placeholder={`Password`}
                value={formData.password}
                onChange={password => handleInputChange({ target: { value: password, name: 'password' } })}
                className="font-medium sm:pr-5"
              />
              {errors.password && <Text color="red" as="p">{errors.password}</Text>}
            </div>
            <div className="flex flex-col items-start self-stretch pb-1 mt-[19px]">
              <Text as="p">Confirm Password</Text>
              <Input
                color="black_900_66"
                size="xs"
                variant="outline"
                shape="round"
                type="password"
                name="confirmPassword"
                placeholder={`Confirm Password`}
                value={formData.confirmPassword}
                onChange={confirmPassword => handleInputChange({ target: { value: confirmPassword, name: 'confirmPassword' } })}
                className="font-medium sm:pr-5"
              />
              {errors.confirmPassword && <Text color="red" as="p">{errors.confirmPassword}</Text>}
            </div>
            <CheckBox
              name="termsAccepted"
              label="I agree to the terms & policy"
              id="checkbox"
              checked={formData.termsAccepted}
              onChange={termsAccepted => handleInputChange({ target: { value: termsAccepted, name: 'termsAccepted' } })}
              className="mt-5 p-px text-left text-[9px] font-medium text-black-900"
            />
            {errors.termsAccepted && <Text color="red" as="p">{errors.termsAccepted}</Text>}
            <Button type="submit" color="light_green_900" size="lg" shape="round" className="mt-[29px] w-full font-bold sm:px-5">
              Signup
            </Button>
          </form>
          {errors.server && <Text color="red" as="p">{errors.server}</Text>}
          <div className="mt-[59px] flex flex-col items-center self-stretch">
            <Text size="xs" as="p" className="relative z-[1] flex items-center justify-center bg-white-A700 px-1 py-px">
              Or
            </Text>
            <div className="relative mt-[-5px] h-[2px] w-full self-stretch bg-gray-100_01" />
          </div>
          {/* <div className="mt-[60px] flex gap-[23px] self-stretch">
          <Button
              color="blue_gray_100"
              size="md"
              variant="outline"
              shape="round"
              leftIcon={<Img src="images/img_icons8google_1.svg" alt="icons8-google 1" className="h-[24px] w-[24px]" />}
              className="w-full gap-2.5 self-end font-medium"
              onClick={() => window.location.href = 'https://migolearn.live/api/auth/google'} // Trigger Google OAuth
            >
              Sign in with Google
            </Button>
            <Button
              color="blue_gray_100"
              size="md"
              variant="outline"
              shape="round"
              leftIcon={<Img src="images/img_icons8applelogo_1.svg" alt="icons8-apple-logo 1" className="h-[24px] w-[24px]" />}
              className="w-full gap-2.5 self-start font-medium sm:px-5"
            >
              Sign in with Apple
            </Button>
          </div> */}
          <div className="mt-[23px] flex">
            <Text as="p">
              <span className="text-black-900">Have an account? &nbsp;</span>
              <button className="text-indigo_A700" onClick={() => navigate('/signinpage',{ replace: true })}>Sign In</button>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
