import React from "react";
import { Button, Text } from "../../components";
import quotableImage from '../../assets/images/Quotable.png';
import galleryImage from '../../assets/images/Gallery.png';
import dividendImage from '../../assets/images/Dividend.png';
import berlinImage from '../../assets/images/Berlin.png';

const images = {
  'Quotable.pptx': quotableImage,
  'Gallery.pptx': galleryImage,
  'Dividend.pptx': dividendImage,
  'Berlin.pptx': berlinImage,
};

// Utility function to remove .pptx extension for button display
const getDisplayName = (filename) => filename.replace('.pptx', '');

export default function LessonAIPagePPTOneCard({
  templatecounter = "Template 1",
  selectTemplate = "Select Template",
  onSelect,
  isSelected, // New prop to determine if selected
  ...props
}) {
  const highlightClass = isSelected ? 'border-4 border-[#FFAEC0]' : '';

  return (
    <div className={`card flex flex-col items-center p-4 bg-white border border-gray-200 rounded-lg shadow-lg ${highlightClass}`} {...props}>
      <img src={images[selectTemplate]} alt={templatecounter} className="w-full h-48 object-cover rounded-t-lg" />
      <div className="flex flex-col items-center justify-center w-full p-4">
        <Button
          size="sm"
          className="min-w-[116px] rounded font-poppins bg-[#FFAEC0] font-medium"
          onClick={() => onSelect(selectTemplate)}
        >
          {getDisplayName(selectTemplate)}
        </Button>
      </div>
    </div>
  );
}
