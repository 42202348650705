import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Img, Text, Heading } from "../../components";
import Sidebar4 from "../../components/Sidebar4";
import axios from "axios";

export default function LessonAIPageAllLessonsPage() {
  const [lessons, setLessons] = useState([]);
  const [learningOutcome, setLearningOutcome] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLessons = async () => {
      const token = localStorage.getItem('token');
      console.log('token:', token);
      
      try {
        const response = await axios.get('https://migolearn.live/api/lessons',
          { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
        setLessons(response.data);
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    };

    fetchLessons();
  }, []);

  // const fetchLearningOutcome = async (id) => {
  //   try {
  //     const response = await axios.get(`https://migolearn.live/api/learning-outcomes/${id}`);
  //     setLearningOutcome(response.data.final_learning_outcomes);
  //   } catch (error) {
  //     console.error('Error fetching learning outcome:', error);
  //   }
  // };

  const handleViewLO = (learningOutcomeId) => {
    navigate(`/LessonAIpagelothree/${learningOutcomeId}`);
  };

  const handleViewID = (instructionalDesignId) => {
    navigate(`/LessonAIpageflowdoctwo/${instructionalDesignId}`);
  };

  const handleViewPPT = (pptId) => {
    navigate(`/LessonAIpagepptthree/${pptId}`);
  };

  const handleViewVideo = (videoId) => {
    navigate(`/LessonAIpagevideotwo/${videoId}`);
  };

  const handleDeleteLesson = async (lessonId) => {
    const token = localStorage.getItem('token');
    console.log('token:', token);
    try {
      await axios.delete(`https://migolearn.live/api/lessons/${lessonId}`,
        { headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`} });
      setLessons(lessons.filter(lesson => lesson._id !== lessonId));
    } catch (error) {
      console.error('Error deleting lesson:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>LearnMigo</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-between gap-5 bg-white-A700 pr-[76px] md:flex-col md:pr-5">
        <Sidebar4 />
        <div className="mt-[23px] flex w-[72%] flex-col items-start gap-[49px] md:w-full">
          <Text size="6xl" as="p" className="ml-[30px] md:ml-0">
            Your Lessons
          </Text>
          <div className="self-stretch border border-solid border-black-900 pb-[480px] md:pb-5">
            <div>
              <div className="flex flex-col gap-px">
                <div className="flex flex-1 items-center justify-between gap-5 bg-white-A700 py-3.5 pl-[53px] pr-[43px] md:flex-col md:p-5 md:px-5">
                  <div className="flex w-[76%] flex-wrap items-center justify-center md:w-full md:flex-col">
                    <Heading as="h1" className="!text-[12.32px]">
                      Lesson ID
                    </Heading>
                    {/* <Heading as="h2" className="ml-[35px] !text-[12.32px] md:ml-0">
                      Lesson Name
                    </Heading> */}
                    <Heading as="h3" className="ml-[45px] !text-[12.32px] md:ml-0">
                      Created on
                    </Heading>
                    <Heading as="h4" className="ml-11 w-[29%] !text-[12.32px] leading-[15px] md:ml-0 md:w-full">
                      <>
                        Learning
                        <br />
                        Outcomes
                      </>
                    </Heading>
                    <Heading
                      as="h5"
                      className="relative ml-[-49px] w-[16%] !text-[12.32px] leading-[15px] md:ml-0 md:w-full"
                    >
                      Instructional Document
                    </Heading>
                    <Heading as="h6" className="ml-[49px] !text-[12.32px] md:ml-0">
                      Presentation
                    </Heading>
                  </div>
                  <div className="flex w-[15%] flex-wrap justify-between gap-5 md:w-full">
                    <Heading as="p" className="!text-[12.32px]">
                      Video
                    </Heading>
                    <Heading as="p" className="!text-[12.32px]">
                      Action
                    </Heading>
                  </div>
                </div>
                {lessons.map((lesson, index) => (
                  <div key={lesson._id} className="flex flex-1 items-start justify-between gap-5 bg-gray-50_01 pb-[15px] pl-[70px] pr-[42px] pt-4 md:flex-col md:p-5 md:px-5">
                    <Text as="p" className="!font-montserrat">
                      #{lesson.lesson_id}
                    </Text>
                    <div className="flex w-[91%] flex-wrap items-center justify-center md:w-full md:flex-col">
                      {/* <Text as="p" className="self-start !font-montserrat">
                        Lesson{lesson.lesson_id}Name
                      </Text> */}
                      <Text as="p" className="ml-10 !font-montserrat md:ml-0">
                        {new Date(lesson.created_at).toLocaleDateString()}
                      </Text>
                      <Text size="xl" as="p" className="ml-[35px] !font-montserrat !text-[13.72px] underline md:ml-0">
                        <button onClick={() => handleViewLO(lesson.learning_outcomes)}>View LO</button>
                      </Text>
                      <Text as="p" className="ml-8 w-[25%] !font-montserrat leading-[17px] underline md:ml-0 md:w-full">
                      <button onClick={() => handleViewID(lesson.instructional_design)}>View Instructional Design</button> 
                      </Text>
                      <Text as="p" className="ml-4 w-[19%] !font-montserrat leading-[17px] underline md:ml-0 md:w-full">
                      <button onClick={() => handleViewPPT(lesson.ppt_data)}>View PPTs</button>
                      </Text>
                      <Text as="p" className="mb-[7px] ml-[15px] self-end !font-montserrat underline md:ml-0">
                      <button onClick={() => handleViewVideo(lesson.video_data)}>View Videos</button>
                      </Text>
                      <Img
                        src="images/img_trash_2_2.svg"
                        alt="trash2two"
                        className="ml-10 h-[24px] w-[24px] md:ml-0 md:w-full"
                        onClick={() => handleDeleteLesson(lesson._id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                ))}
                {learningOutcome && (
                  <div className="p-5 bg-gray-100">
                    <h3>Learning Outcomes</h3>
                    <p>{learningOutcome}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
